import { Component, Inject } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { AccountService } from 'src/app/admin-portal/modules/account/services/account.service';
import { UserManagementService } from 'src/app/admin-portal/modules/user-management/services/user-management.service';
import { nameValidator } from 'src/app/utils/name-validation.utils';
import { whitespaceValidator } from 'src/app/utils/whitespace-validation.utils';

@Component({
  selector: 'app-dialog-edit-user-management',
  templateUrl: './user-management-dialog.component.html',
})
export class UserManagementDialogComponent {
  userForm!: FormGroup;
  isEditMode: boolean = false;
  accounts: any[] = [];
  roleAndPermission: any[] = [];
  isButtonDisabled: boolean = true;
  errorMessage: string | null = null;
  isSubmitting: boolean = false;

  constructor(
    public matDialog: MatDialogRef<UserManagementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private userManagementService: UserManagementService,
    private globalService: GlobalService,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.isEditMode = this.data.isEditMode;
    this.initializeForm();
    this.loadAccounts();

    this.userForm.valueChanges.subscribe(() => {
      this.isButtonDisabled = this.userForm.invalid || !this.userForm.dirty;
    });

    if (this.isEditMode && this.data.element) {
      this.patchFormValues(this.data.element);
      this.userForm.get('email')?.disable();
      this.userForm.get('password')?.disable();
    }

    this.isButtonDisabled = true;
    this.getRolePermissionList();
  }

  getRolePermissionList(): void {
    this.userManagementService
      .getRolePermissionList(0, 100, '', 'title', 'ASC')
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data) => {
          this.roleAndPermission = data?.data?.records || [];
        },
        error: (error) => {
          console.error('Error fetching role and permission list:', error);
        },
      });
  }

  loadAccounts(): void {
    this.accountService
      .getAccountList(0, 100, '', 'accountName', 'ASC')
      .subscribe({
        next: (data) => {
          this.accounts = data?.data?.records;
        },
        error: (error: any) => {
          console.error('Error fetching accounts', error);
        },
      });
  }

  initializeForm(): void {
    this.userForm = this.formBuilder.group({
      name: ['', [Validators.required, whitespaceValidator(), nameValidator]],
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
          ),
        ],
      ],
      userID: [''],
      userRoleID: [''],
      portolType: ['', Validators.required],
      associatedAccountID: [''],
    });

    this.userForm.get('portolType')?.valueChanges.subscribe((portalType) => {
      const accountIDControl = this.userForm.get('associatedAccountID');
      const userRoleIDControl = this.userForm.get('userRoleID');

      if (portalType === 'customer') {
        accountIDControl?.setValidators([Validators.required]);
        userRoleIDControl?.clearValidators();
      } else if (portalType === 'admin') {
        userRoleIDControl?.setValidators([Validators.required]);
        accountIDControl?.clearValidators();
      }

      accountIDControl?.updateValueAndValidity();
      userRoleIDControl?.updateValueAndValidity();
    });

    // Custom validator to check if both account and role are empty
    this.userForm.setValidators(this.accountAndRoleValidator());
  }

  accountAndRoleValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const formGroup = control as FormGroup;
      const portalType = formGroup.get('portolType')?.value;
      const accountID = formGroup.get('associatedAccountID')?.value;
      const roleID = formGroup.get('userRoleID')?.value;

      if (portalType === 'customer' && !accountID) {
        return { accountRequired: true };
      } else if (portalType === 'admin' && !roleID) {
        return { roleRequired: true };
      } else {
        return null;
      }
    };
  }

  patchFormValues(element: any) {
    this.userForm.patchValue({
      userID: element.userID,
      name: element.name,
      email: element.email,
      password: '12345678',
      userRoleID: element?.role?.userRoleID,
      portolType: element?.portolType,
      associatedAccountID: element?.associatedAccount?.accountID,
    });
  }

  onSave(): void {
    if (this.userForm.valid && !this.isSubmitting) {
      this.isSubmitting = true;

      const {
        name,
        email,
        userRoleID,
        password,
        portolType,
        associatedAccountID,
      } = this.userForm.value;
      const userID = this.userForm.get('userID')?.value;

      // If in edit mode, update the user
      if (this.isEditMode) {
        const updatedUserData = {
          name,
          userID,
          userRoleID,
          portolType,
          associatedAccountID,
        };

        this.userManagementService
          .editUserList(updatedUserData)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: () => {
              this.isSubmitting = false;
              this.matDialog.close({ success: true });
            },
            error: (errorResponse: any) => {
              this.errorMessage = errorResponse;
              this.isSubmitting = false;
            },
          });
      } else {
        let userData: any;

        if (portolType === 'admin') {
          userData = {
            name,
            email,
            userRoleID,
            password,
            portolType,
          };
        } else if (portolType === 'customer') {
          userData = {
            name,
            email,
            associatedAccountID,
            password,
            portolType,
          };
        }

        this.userManagementService
          .createUser(userData)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: () => {
              this.isSubmitting = false;
              this.matDialog.close({ success: true });
            },
            error: (errorResponse: any) => {
              this.errorMessage = errorResponse;
              this.isSubmitting = false;
            },
          });
      }
    } else {
      console.error('Invalid form data.');
    }
  }

  close() {
    this.matDialog.close();
  }
}
